import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import DangerIcon from '@shared/components/new-design/images/danger.png';

import { styles } from './page-not-found.styles';

export interface PageNotFoundProps extends AppWithStyles<typeof styles> {}

const PageNotFoundComponent: FC<PageNotFoundProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <img src={String(DangerIcon)} alt={''} />
      <div className={classes.text}>{t`Page not found`}</div>
    </div>
  );
};
export default appWithStyles(styles)(PageNotFoundComponent);
