import React, { useCallback, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import {
  ConfirmationDialog,
  ConfirmationDialogButton,
} from '@shared/components/confirmation-dialog';
import { Loading } from '@shared/components/loading';
import { AppSwitch } from '@shared/components/switch';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import * as stripeJs from '@stripe/stripe-js';
import classNames from 'classnames';

import { styles, StyledFormHelperText, StyledLabel } from './add-card-dialog.styles';

type AddCardDialogProps = AppWithStyles<typeof styles> & {
  isOpen: boolean;
  onCancel: () => void;
  loading: boolean;
  onSave: (data: any, save: boolean) => void;
  showSave: boolean;
  confirmButtonLabel?: string;
};

interface CardErrorProps {
  card: string | null;
  exp: string | null;
  cvc: string | null;
}

const AddCardDialogComponent: React.FC<AddCardDialogProps> = ({
  classes,
  isOpen,
  onSave,
  onCancel,
  loading,
  showSave,
  confirmButtonLabel,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  //const $vm = useMemo(() => new AddCardDialogViewModel(), []);

  const [saveCard, setSaveCard] = useState<boolean>(showSave ? false : true);
  const [errors, setErrors] = useState<CardErrorProps>({ card: null, exp: null, cvc: null });
  const [cardState, setCardState] = useState<stripeJs.StripeCardNumberElementChangeEvent | null>(
    null,
  );
  const [expirationState, setExpirationState] =
    useState<stripeJs.StripeCardExpiryElementChangeEvent | null>(null);
  const [cvcState, setCvcState] = useState<stripeJs.StripeCardCvcElementChangeEvent | null>(null);

  const handleSave = useCallback(() => {
    const errors: CardErrorProps = { card: null, exp: null, cvc: null };
    if (cardState === null || cardState.empty) {
      errors.card = t`This field is mandatory`;
    } else if (!cardState.complete) {
      errors.card = t`Wrong card details`;
    }

    if (expirationState === null || expirationState.empty) {
      errors.exp = t`This field is mandatory`;
    } else if (!expirationState.complete) {
      errors.exp = t`Wrong card details`;
    }

    if (cvcState === null || cvcState.empty) {
      errors.cvc = t`This field is mandatory`;
    } else if (!cvcState.complete) {
      errors.cvc = t`Wrong card details`;
    }

    if (errors.card === null && errors.exp === null && errors.cvc === null) {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded. Make sure to disable form submission until Stripe.js has loaded.
        alert(t`Please try again`);
        return;
      }
      onSave({ stripe, elements }, saveCard);
    }
    setErrors(errors);
  }, [onSave, stripe, elements, cardState, expirationState, cvcState, errors, setErrors, saveCard]);

  const handleClose = useCallback(() => {
    setErrors({ card: null, exp: null, cvc: null });
    onCancel();
  }, [onCancel, setErrors]);

  const handleChangeSaveCard = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setSaveCard(checked),
    [setSaveCard],
  );
  const handleCardValueChange = useCallback(
    (event: stripeJs.StripeCardNumberElementChangeEvent) => {
      setCardState(event);
      setErrors((errors) => ({ ...errors, ...{ card: null } }));
    },
    [setCardState, setErrors],
  );
  const handleCardReady = useCallback(
    (element: stripeJs.StripeCardNumberElement) => element.focus(),
    [],
  );
  const handleExpirationValueChange = useCallback(
    (event: stripeJs.StripeCardExpiryElementChangeEvent) => {
      setExpirationState(event);
      setErrors((errors) => ({ ...errors, ...{ exp: null } }));
    },
    [setExpirationState, setErrors],
  );
  const handleCVCValueChange = useCallback(
    (event: stripeJs.StripeCardCvcElementChangeEvent) => {
      setCvcState(event);
      setErrors((errors) => ({ ...errors, ...{ cvc: null } }));
    },
    [setCvcState, setErrors],
  );

  const buttons: ConfirmationDialogButton[] = [
    { label: t`Cancel`, onClick: handleClose, variant: 'text', color: 'primary' },
    { label: confirmButtonLabel ? confirmButtonLabel : t`Save card`, onClick: handleSave },
  ];

  const fieldOptions = {
    classes: {
      base: classes.field,
      focus: classes.fieldFocused,
      empty: classes.fieldEmpty,
    },
    style: {
      base: {
        fontSize: '13px',
        lineHeight: '19px',
      },
    },
  };
  const cardOptions: stripeJs.StripeCardNumberElementOptions = {
    showIcon: true,
    iconStyle: 'solid',
    placeholder: t`Card Number`,
    ...fieldOptions,
  };
  const expOptions: stripeJs.StripeCardExpiryElementOptions = {
    placeholder: t`MM/YY`,
    ...fieldOptions,
  };
  const cvcOptions: stripeJs.StripeCardCvcElementOptions = {
    placeholder: t`CVC`,
    ...fieldOptions,
  };

  return (
    <ConfirmationDialog
      title={t`Add new card`}
      {...{ buttons: loading ? [] : buttons, isOpen, onClose: handleClose }}
    >
      {loading && (
        <div className={classes.root}>
          <Loading size={30} />
        </div>
      )}

      {/* Do not remove from DOM*/}
      <div className={classNames(classes.root, { [classes.hidden as string]: loading })}>
        <div className={classes.block}>
          <div className={classes.label}>{t`Card Number`}</div>
          <CardNumberElement
            className={classNames({ [classes.error as string]: errors.card })}
            options={cardOptions}
            onChange={handleCardValueChange}
            onReady={handleCardReady}
          />
          {errors.card !== null && <StyledFormHelperText>{errors.card}</StyledFormHelperText>}
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <div className={classes.label}>{t`Expiration`}</div>
            <CardExpiryElement
              className={classNames({ [classes.error as string]: errors.exp })}
              options={expOptions}
              onChange={handleExpirationValueChange}
            />
            {errors.exp !== null && <StyledFormHelperText>{errors.exp}</StyledFormHelperText>}
          </div>
          <div className={classes.col}>
            <div className={classes.label}>{t`CVC`}</div>
            <CardCvcElement
              className={classNames({ [classes.error as string]: errors.cvc })}
              options={cvcOptions}
              onChange={handleCVCValueChange}
            />
            {errors.cvc !== null && <StyledFormHelperText>{errors.cvc}</StyledFormHelperText>}
          </div>
        </div>
        {showSave && (
          <div className={classes.block}>
            <StyledLabel
              control={<AppSwitch checked={saveCard} onChange={handleChangeSaveCard} />}
              label={t`Save the card`}
            />
          </div>
        )}
      </div>
    </ConfirmationDialog>
  );
};

export const AddCardDialog = appWithStyles(styles)(AddCardDialogComponent);
