import { IssueType } from '@shared/enums/issue-type.enum';
import { SmartCoinOrderType } from '@shared/models/smart-coin-order-status/smart-coin-order-type';

export const ROUTES = {
  initial: '/',
  unknown: '/404',
  error: '/error-screen',
  errorAuth: '/error',
  static: {
    root: '/static',
    welcome: '/static/welcome',
  },
  onboarding: '/onboarding',
  public: {
    root: '/auth',
    login: (email: string = '') =>
      Boolean(email) ? `/auth/login?userEmail=${encodeURIComponent(email)}` : '/auth/login',
    register: '/auth/register',
    passwordRecovery: '/auth/password-recovery',
    deleteAccountResult: '/auth/deleted',
  },
  desktopPrivate: {
    root: '/home',
    maintenancePage: '/maintenance',
    dashboard: '/home/dashboard',
    market: '/home/market',
    wallets: {
      root: '/home/wallets',
      topUp: '/home/wallets/top-up',
      children: {
        order: (
          orderType: SmartCoinOrderType | string = ':orderType',
          smartCoinName: string = ':smartCoinName',
        ) => `${ROUTES.desktopPrivate.wallets.root}/${orderType}/${smartCoinName}`,
      },
    },
    transfers: {
      root: '/home/transfers',
      children: {
        new: `/home/transfers/new`,
      },
    },
    orders: '/home/orders',
    settings: {
      root: '/home/settings',
      personalInfo: '/home/settings/personal',
      changeAvatar: '/home/settings/avatar',
      certificates: '/home/settings/certificates',
      documents: '/home/settings/documents',
      helpCenter: {
        root: '/home/settings/help-center',
        issue: (type: IssueType | string = ':issueType') => `/home/settings/help-center/${type}`,
      },
      security: {
        root: '/home/settings/security',
        phone: 'home/settings/security/phone',
        changePassword: '/home/settings/security/change-password',
      },
      cards: '/home/settings/cards',
      currency: '/home/settings/currency',
      notifications: '/home/settings/notifications',
      deleteAccount: '/home/settings/delete-account',
    },
  },
  mobilePrivate: {
    root: '/mobile',
    maintenancePage: '/mobile/maintenance',
    purchaseGSC: '/mobile/purchase-and-sell-mobile-form',
    dashboard: '/mobile/dashboard',
    activity: '/mobile/activity',
    market: '/mobile/market',
    transactions: '/mobile/transactions',
    settings: {
      root: '/mobile/settings',
      personalInfo: '/mobile/settings/personal',
      changeAvatar: '/mobile/settings/avatar',
      certificates: '/mobile/settings/certificates',
      documents: '/mobile/settings/documents',
      helpCenter: {
        root: '/mobile/settings/help-center',
        issue: (type: IssueType | string = ':issueType') => `/mobile/settings/help-center/${type}`,
      },
      security: {
        root: '/mobile/settings/security',
        phone: '/mobile/settings/security/phone',
        changePassword: '/mobile/settings/security/change-password',
      },
      cards: '/mobile/settings/cards',
      currency: '/mobile/settings/currency',
      notifications: '/mobile/settings/notifications',
      deleteAccount: '/mobile/settings/delete-account',
    },
    topUp: '/mobile/top-up',
    topUpByCurrency: (currency: string) => `/mobile/top-up?currency=${currency}`,
    transfer: '/mobile/transfer',
    wallets: {
      root: '/mobile/wallets',
      children: {
        order: (
          orderType: SmartCoinOrderType | string = ':orderType',
          smartCoinName: string = ':smartCoinName',
        ) => `${ROUTES.mobilePrivate.wallets.root}/${orderType}/${smartCoinName}`,
      },
    },
    orders: {
      root: '/mobile/orders',
      children: {
        details: (orderId: string = ':orderId') =>
          `${ROUTES.mobilePrivate.orders.root}/details/${orderId}`,
      },
    },
  },
  verification: `/verification`,
  payments3DSercureForm: '/3d-secure',
  paymentSuccessScreen: '/success-screen',
  appVerification: '/app-verification',
  digitalTokensInformation: '/digital-tokens-information',
  termsAndConditions: '/terms-and-conditions',
  welcomeScreen: '/welcome-screen',
};
