import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      height: '100vh',
      width: '100%',
      background: 'linear-gradient(180deg, #130F20 0%, #09070E 100%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    text: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.2px',
      marginTop: '28px',
      textAlign: 'center',
    },
  });
}
