export enum TokenRefreshStatus {
  refreshing,
  refreshed,
  initial,
}

export enum AuthStatus {
  loggedOut = 'loggedOut',
  mfaSetup = 'mfaSetup',
  mfaRequiredSMS = 'mfaRequiredSMS',
  passwordChangeRequired = 'passwordChangeRequired',
  registeredUnconfirmed = 'registeredUnconfirmed',
  loggedIn = 'loggedIn',
}
