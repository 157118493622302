import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      height: _var(CSS_VARIABLES.appHeight),
      width: '100%',
      background: 'linear-gradient(180deg, #130F20 0%, #09070E 100%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '10vh',
      [breakpoints.up(Layout.laptop)]: {
        height: '100vh',
      },
    },
    button: {
      width: '200px',
    },
    text: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.2px',
      margin: '28px 0px',
      textAlign: 'center',
    },
    outlinedButton: {
      width: '150px',
      height: '47px',
      borderRadius: '10px',
      border: '1px solid #7543FF',
      color: '#FFF',
      textAlign: 'center',
      fontFamily: 'Urbanist',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  });
}
