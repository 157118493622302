import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import DangerIcon from '@shared/components/new-design/images/danger.png';
import { OutlinedButton } from '@shared/components/new-design/outlined-button';
import classNames from 'classnames';

import { styles } from './page-error-private.styles';

export interface PageNotFoundProps extends AppWithStyles<typeof styles> {}

const PageErrorPrivateComponent: FC<PageNotFoundProps> = ({ classes }) => {
  const onClick = () => {
    location.reload();
  };

  return (
    <div className={classNames(classes.root, 'without-bottom-padding')}>
      <img src={String(DangerIcon)} alt={''} />
      <div className={classes.text}>
        {t`Oops..!`}
        <br />
        {t`Something went wrong. Please try again in a few minutes.`}
      </div>
      <div className={classes.button}>
        <OutlinedButton onSubmit={onClick} label={t`Try again`} />
      </div>
    </div>
  );
};
export default appWithStyles(styles)(PageErrorPrivateComponent);
