import { appInject } from '@core/di/utils';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { INotificationService } from '@shared/types/notification-service';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { wait } from '@shared/utils/wait';

enum ComponentIdList {
  FORM_ID = '#worldpay-form',
  CARD_NUMBER = '#card-pan',
  CARD_EXPIRE = '#card-expire',
  CARD_CVV = '#card-cvc',
}

export class AddCardComponentViewModel {
  private paymentsService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);
  private notificationService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private _checkoutSDKForm: any;

  constructor() {}

  async intiForm() {
    if (!this._checkoutSDKForm) {
      await wait(0);
      try {
        this._checkoutSDKForm = await this.paymentsService.init({
          formId: ComponentIdList.FORM_ID,
          cardNumberFieldId: ComponentIdList.CARD_NUMBER,
          cardExpireFieldId: ComponentIdList.CARD_EXPIRE,
          cvvFieldId: ComponentIdList.CARD_CVV,
          styles: this.generateStyles(),
        });
      } catch (ex) {
        console.warn(`Failed to init SDK: ${ex}`);
        this.notificationService.showError(t`Payment provider is not available. Try again`);
      }
    }
  }

  createSession(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._checkoutSDKForm.generateSessionState((error: any, session: any) => {
        if (error) {
          console.warn(`Failed to generate session: ${error}`);
          this.notificationService.showError(t`Your card was declined`);
          return reject(error);
        } else {
          return resolve(session);
        }
      });
    });
  }

  removeCheckoutSDK() {
    if (this._checkoutSDKForm) {
      this._checkoutSDKForm.remove();
      this._checkoutSDKForm = null;
    }
  }

  generateStyles() {
    return {
      input: {
        color: `#FFF`,
        'font-size': '16x',
      },
      'input.is-valid': {
        color: `#FFF`,
      },
      'input.is-invalid': {
        color: `#FF2C2C`,
      },
    };
  }

  get isLoading() {
    return Boolean(this._checkoutSDKForm);
  }

  isInvalidCardNumber() {
    return this.isExistsComponent(`${ComponentIdList.CARD_NUMBER}.is-invalid`);
  }

  isInvalidExpiration() {
    return this.isExistsComponent(`${ComponentIdList.CARD_EXPIRE}.is-invalid`);
  }

  isInvalidCVV() {
    return this.isExistsComponent(`${ComponentIdList.CARD_CVV}.is-invalid`);
  }

  isInvalidCardHolder(name: string) {
    return !Boolean(name.trim().length);
  }

  private isExistsComponent(selector: string): boolean {
    return Boolean(document.querySelector(selector));
  }
}
