import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { FormControlLabel, Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {},
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      background: '#161524',
      minHeight: '550px',
      position: 'relative',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
      },
    },
    hidden: {
      display: 'none',
    },
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '13px',
      marginBottom: '27px',
    },
    submit: {
      position: 'absolute',
      background: 'transparent',
      bottom: '20px',
      width: 'calc(100% - 40px)',
      display: 'flex',
      '& button + button': {
        marginLeft: '15px',
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        height: 'auto',
        maxHeight: '90%',
        background: '#161524',
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    label: {
      color: '#FFF',
      opacity: 0.6,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      marginBottom: '6px',
    },
    error: {},
    field: {
      height: '52px',
      padding: '17px 16px',
      position: 'relative',
      color: '#FFF',
      fontSize: '16px !important',
      fontWeight: 500,
      letterSpacing: '0.08px',
      borderRadius: '12px',
      background: 'rgba(255, 255, 255, 0.06)',
      '& input#card-pan': {
        fontSize: '16px !important',
        color: '#fff',
      },

      '&#card-holder-name': {
        padding: '6px 17px',
      },
      '&::placeholder': {
        fontSize: '16px !important',
        color: 'rgba(255, 255, 255, 0.20)',
        fontWeight: 500,
        letterSpacing: '0.08px',
      },
      '&.is-onfocus': {
        border: `1px solid #7543FF`,
      },
      '&.is-invalid': {
        color: '#FF2C2C',
        border: '1px solid #FF2C2C',
        fontSize: '16px !important',
        fontWeight: 500,
        letterSpacing: '0.08px',
      },
      '&$error': {
        color: '#FF2C2C',
        border: '1px solid #FF2C2C',
        fontSize: '16px !important',
        fontWeight: 500,
        letterSpacing: '0.08px',
      },
    },
    block: {
      marginBottom: '24px',
    },
    row: {
      display: 'flex',
      marginBottom: '24px',
    },
    col: {
      '& + &': {
        marginLeft: '19px',
      },
    },
    cardHolderName: {
      width: '100%',
      height: '100%',
      background: 'transparent',
      border: 'none',
      color: '#FFF',
      fontSize: '16px !important',
      fontWeight: 500,
      letterSpacing: '0.08px',
      outline: 'none !important',
    },
  });
}

export const StyledLabel = appWithStyles(() => ({
  root: {
    marginLeft: 0,
  },
  label: {
    marginLeft: '10px',
    color: '#FFF',
    opacity: 0.6,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.3px',
  },
}))(FormControlLabel);
