import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

import leftIcon from './img/arrow-left.svg';
import rightIcon from './img/arrow-right.svg';

export function styles({ spacing }: AppTheme) {
  return appCreateStyles({
    root: {
      position: 'relative',
      width: '100%',

      '&:hover $arrows': {
        '@media (hover: hover)': {
          opacity: 1,
          right: 0,
        },
      },
    },
    titleBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      '$selectable &': {
        marginBottom: '0px',
      },
    },
    flex: {
      display: 'flex',
    },
    title: {
      color: '#fff',
      fontSize: '16px',
      fontWeight: 400,
      marginLeft: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    buttonsBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      cursor: 'pointer',
      height: '30px',
      width: '90px',
      padding: '8px 16px',
      backgroundColor: '#473763',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFF',
      fontSize: '11px',
      fontWeight: 700,
      textTransform: 'uppercase',
      '& + &': {
        marginLeft: '10px',
      },
    },
    selectable: {},
    arrows: {
      marginLeft: '20px',
      pointerEvents: 'none',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      width: 73,
      transition: 'all 0.3s',
    },
    arrow: {
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'block',
      width: 30,
      height: 30,
      backgroundSize: '24px auto',
      textIndent: -200,
      overflow: 'hidden',
      transition: 'all 0.3s',

      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    prev: {
      background: `url(${leftIcon}) no-repeat center center`,
    },
    next: {
      background: `url(${rightIcon}) no-repeat center center`,
    },

    scroll: {
      scrollBehavior: 'smooth',
      transition: 'all 0.3s',
      display: 'flex',
      paddingBottom: 10,
      scrollPadding: '0 0 0 26px',
      scrollSnapType: 'x mandatory',
      '$selectable &': {
        minHeight: '256px',
      },
    },
    dragging: {
      //only if not touch device allow cursor drag
      '@media (hover: hover)': {
        scrollSnapType: 'block',
      },
    },
    noAction: {
      pointerEvents: 'none',
    },
    noData: {
      width: '100%',
      textAlign: 'center',
      alignSelf: 'center',
      justifySelf: 'center',
      margin: '0 0 0 26px',
      padding: '20px 15px 15px',
      color: _var(CSS_VARIABLES.textSecondaryColor),
    },
    spacer: {
      scrollSnapAlign: 'start',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 26,
      width: 26,
    },
    slide: {
      scrollSnapAlign: 'center',
      '@media (hover: hover)': {
        scrollSnapAlign: 'start',
      },
      position: 'relative',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 290,
      bottom: 0,
      borderRadius: '10px',
      cursor: 'pointer',
      transition: 'all 0.2s ease-in-out',
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
    },

    details: {
      height: '21px',
      lineHeight: '21px',
      fontWeight: 300,
      color: _var(CSS_VARIABLES.mainColor),
    },

    controls: {
      position: 'absolute',
      bottom: '15px',
      right: '15px',
      left: '15px',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    wallet: {
      height: '222px',
      width: 'fit-content',
    },
  });
}
