import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';

export class AddCardComponentViewModel {
  private paymentsService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);

  constructor() {}

  get stripe() {
    return this.paymentsService.instance;
  }
}
