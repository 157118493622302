import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { WalletsDialog } from '@modules/new-private/wallets/top-up/components/wallets-dialog';
import { Theme, useMediaQuery } from '@mui/material';
import { KYCDialogDialog } from '@shared/components/new-design/kyc-dialog/kyc-dialog';
import { VerificationDialogDialog } from '@shared/components/new-design/verification-dialog/verification-dialog';
import Wallet from '@shared/components/new-design/wallets-carousel/components/wallet/wallet';
import { useNavigate } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { ProductModel, ProductType } from '@shared/models/products/product-model';
import { SmartCoinOrderType } from '@shared/models/smart-coin-order-status/smart-coin-order-type';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IProductsVM } from '@shared/types/products-vm';
import { IUsersService } from '@shared/types/users-service';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import ScrollContainer from 'react-indiana-drag-scroll';

import { styles } from './wallets-carousel.styles';

export interface WalletsCarouselProps extends AppWithStyles<typeof styles> {
  wallets: WalletsListModel[];
  onSelectWallet: (wallet: WalletsListModel) => void;
  selectable?: boolean;
  totalAmount: string;
}

const ITEM_WIDTH = 365;

const WalletsCarouselComponent: React.FC<WalletsCarouselProps> = ({
  classes,
  wallets,
  onSelectWallet,
  selectable,
  totalAmount,
}) => {
  const scrollRef = useRef<ScrollContainer | null>(null);
  const [activeWallet, setActiveWallet] = useState<WalletsListModel | null>(null);
  const [isOpenWalletsDialog, setIsOpenWalletsDialog] = useState<boolean>(false);
  const [isOpenKYCDialog, setIsOpenKYCDialog] = useState<boolean>(false);
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.laptop));
  const usersService = useMemo(() => appInject<IUsersService>(DI_TOKENS.usersService), []);
  const [redirectRoute, setRedirectRoute] = useState<string>('');

  const onCloseKYCDialog = () => {
    setIsOpenKYCDialog(false);
  };

  const onCloseVerificationDialog = (result: boolean) => {
    if (result) {
      if (isMobile) {
        navigate(ROUTES.verification, { state: { redirect: redirectRoute } });
      } else {
        setIsOpenKYCDialog(true);
      }
    }
    setIsOpenVerificationDialog(false);
  };

  const handleClickBuyButton = () => {
    const route = isMobile
      ? ROUTES.mobilePrivate.wallets.children.order(SmartCoinOrderType.PURCHASE, Currency.GSC)
      : ROUTES.desktopPrivate.wallets.children.order(SmartCoinOrderType.PURCHASE, Currency.GSC);

    if (usersService.isNeedKYC) {
      setRedirectRoute(route);
      setIsOpenVerificationDialog(true);
    } else {
      navigate(route);
    }
  };

  const handleClickSellButton = () => {
    const route = isMobile
      ? ROUTES.mobilePrivate.wallets.children.order(SmartCoinOrderType.SELL, Currency.GSC)
      : ROUTES.desktopPrivate.wallets.children.order(SmartCoinOrderType.SELL, Currency.GSC);
    if (usersService.isNeedKYC) {
      setRedirectRoute(route);
      setIsOpenVerificationDialog(true);
    } else {
      navigate(route);
    }
  };

  const handleClickDeposit = () => {
    const route = isMobile ? ROUTES.mobilePrivate.topUp : ROUTES.desktopPrivate.wallets.topUp;
    if (usersService.isNeedKYC) {
      setRedirectRoute(route);
      setIsOpenVerificationDialog(true);
    } else {
      navigate(route);
    }
  };

  const handleClickWithdraw = () => {
    setIsOpenWalletsDialog(true);
  };

  const handleOnClickSend = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.transfer)
      : navigate(ROUTES.desktopPrivate.transfers.children.new);
  };

  const productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);

  const handleSelectWallet = (wallet: WalletsListModel) => {
    setActiveWallet(wallet);
    onSelectWallet(wallet);
  };

  useEffect(() => {
    if (!activeWallet && wallets && wallets.length) {
      handleSelectWallet(wallets[0] as WalletsListModel);
      setTimeout(() => {
        const scrollDiv = scrollRef?.current?.getElement();
        if (scrollDiv) {
          scrollDiv.scrollTo(0, 0);
        }
      }, 101);
    }
  }, []);

  const isNeedScrollButtons = () => {
    const scrollDiv = scrollRef?.current?.getElement();
    return scrollDiv && scrollDiv.clientWidth < ITEM_WIDTH * wallets.length;
  };

  const handleNext = useCallback(() => {
    const scrollDiv = scrollRef?.current?.getElement();
    if (scrollDiv) {
      scrollDiv.scrollTo(scrollDiv.scrollLeft + ITEM_WIDTH, 0);
    }
  }, [scrollRef]);

  const handlePrev = useCallback(() => {
    const scrollDiv = scrollRef?.current?.getElement();
    if (scrollDiv) {
      scrollDiv.scrollTo(scrollDiv.scrollLeft - ITEM_WIDTH, 0);
    }
  }, [scrollRef]);

  if (wallets.length === 0) {
    return (
      <div className={classes.root}>
        <p className={classes.noData}>No Wallets available</p>
      </div>
    );
  }

  const handleSelectSellWallet = (walletId: string) => {
    const wallet = wallets.find((w) => w.asJson.id === walletId) as WalletsListModel;
    const product = productsVM.getProductByCurrency(wallet.asJson.currency) as ProductModel;
    const orderType =
      product.asJson.type === ProductType.SMART_COIN
        ? SmartCoinOrderType.SELL
        : SmartCoinOrderType.SELL_FIAT_TOKEN;
    isMobile
      ? navigate(ROUTES.mobilePrivate.wallets.children.order(orderType, product.asJson.name))
      : navigate(ROUTES.desktopPrivate.wallets.children.order(orderType, product.asJson.name));
  };

  const walletsDialog = (
    <WalletsDialog
      title={t`Choose Account to Withdraw`}
      description={''}
      isOpen={isOpenWalletsDialog}
      metals={[]}
      wallets={wallets
        .filter(
          (w) => productsVM.getProductTypeByCurrency(w.asJson.currency) === ProductType.FIAT_COIN,
        )
        .sort(
          (prev, next) =>
            (productsVM.getProductByCurrency(next.asJson.currency)?.asJson.viewPriority || 0) -
            (productsVM.getProductByCurrency(prev.asJson.currency)?.asJson.viewPriority || 0),
        )}
      onClose={() => setIsOpenWalletsDialog(false)}
      onSelectWallet={handleSelectSellWallet}
    />
  );

  const isActiveWallet = (wallet: WalletsListModel) => {
    return selectable && Boolean(activeWallet) && activeWallet?.asJson.id === wallet.asJson.id;
  };

  return (
    <div className={classNames(classes.root, { [classes.selectable]: selectable })}>
      <div className={classes.titleBlock}>
        <div className={classes.flex}>
          <div className={classes.title}>{totalAmount}</div>
          {isNeedScrollButtons() ? (
            <div className={classes.arrows}>
              <span onClick={handlePrev} className={classNames(classes.arrow, classes.prev)}>
                Prev
              </span>
              <span onClick={handleNext} className={classNames(classes.arrow, classes.next)}>
                Next
              </span>
            </div>
          ) : (
            <> </>
          )}
        </div>
        {selectable ? (
          <></>
        ) : (
          <div className={classes.buttonsBlock}>
            <div className={classes.button} onClick={handleClickDeposit}>{t`Deposit`}</div>
            <div className={classes.button} onClick={handleClickBuyButton}>{t`Buy Gold`}</div>
            <div className={classes.button} onClick={handleClickSellButton}>{t`Sell Gold`}</div>
            <div className={classes.button} onClick={handleClickWithdraw}>{t`Withdraw`}</div>
            <div className={classes.button} onClick={handleOnClickSend}>{t`Send`}</div>
          </div>
        )}
      </div>
      <ScrollContainer
        ref={scrollRef as MutableRefObject<ScrollContainer | null>}
        className={classes.scroll}
        draggingClassName={classes.dragging}
        vertical={false}
      >
        {wallets.map((wallet, index) => {
          return (
            <div
              className={classes.slide}
              data-clickable={true}
              key={index}
              onClick={() => handleSelectWallet(wallet)}
            >
              <Wallet
                wallet={wallet}
                selected={isActiveWallet(wallet)}
                product={productsVM.getProductByCurrency(wallet.asJson.currency) as ProductModel}
              />
            </div>
          );
        })}
        <div className={classes.spacer}>&nbsp;</div>
      </ScrollContainer>
      {walletsDialog}
      <VerificationDialogDialog
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
      />
      <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
    </div>
  );
};

export default appWithStyles(styles)(WalletsCarouselComponent);
