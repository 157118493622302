import { AppTheme } from '@core/theme/types/main';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { Switch } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const AppSwitch = appWithStyles(({ palette, transitions }: AppTheme) => ({
  root: {
    width: 32,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: palette.common.white,
        '& + .MuiSwitch-track': {
          backgroundColor: _var(CSS_VARIABLES.accentColor),
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        opacity: 0.7,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      color: _var(CSS_VARIABLES.backgroundEmptyColor),
      boxSizing: 'border-box',
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      backgroundColor: _var(CSS_VARIABLES.textSubTitlesColor),
      opacity: 1,
      transition: transitions.create(['background-color'], {
        duration: 500,
      }),
    },

    // Disabled
    '& .MuiSwitch-switchBase.Mui-disabled': {
      '& .MuiSwitch-thumb': {
        color: '#ffffff',
        opacity: '1 !important',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#E7E7E9',
      },
    },
  },
}))(Switch);
