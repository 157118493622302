import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const StyledTableHead = appWithStyles(() =>
  appCreateStyles({
    root: {
      whiteSpace: 'nowrap',
    },
  }),
)(TableHead);

export const StyledTableCell = appWithStyles(() =>
  appCreateStyles({
    root: {
      fontSize: '15px',
      fontWeight: 300,
      color: _var(CSS_VARIABLES.textSubTitlesColor),
      textAlign: 'left',
      position: 'relative',
    },
    head: {
      padding: '10px 17px',
      lineHeight: '22px',
      backgroundColor: _var(CSS_VARIABLES.backgroundMainColor),
      border: 0,
    },
    body: {
      padding: '17px',
      fontSize: 14,
      lineHeight: '20px',
      color: _var(CSS_VARIABLES.mainColor),
      borderColor: _var(CSS_VARIABLES.bordersMainColor),
    },
  }),
)(TableCell);
