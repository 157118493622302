import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { FormControlLabel, FormHelperText } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import cvcIcon from './img/cvc-icon.png';

export const styles = ({ breakpoints }: AppTheme) => {
  return appCreateStyles({
    root: {
      width: '345px',
      [breakpoints.down(Layout.mobile)]: {
        width: '311px',
        padding: '0 8px',
      },
    },
    hidden: {
      height: 0,
      overflow: 'hidden',
    },
    field: {
      padding: '8px 17px',
      color: _var(CSS_VARIABLES.mainError),
      fontSize: '16px !important',
      border: `1px solid ${_var(CSS_VARIABLES.bordersMainColor)}`,
      borderRadius: '4px',

      '&::placeholder': {
        fontSize: '16px !important',
        color: '#aab7c4',
      },
    },
    error: {
      padding: '8px 17px',
      color: _var(CSS_VARIABLES.mainError),
      fontSize: '16px !important',
      border: `1px solid ${_var(CSS_VARIABLES.mainError)}`,
      borderRadius: '4px',

      '&::placeholder': {
        fontSize: '16px !important',
        color: '#aab7c4',
      },
    },
    fieldFocused: {
      borderColor: _var(CSS_VARIABLES.accentColor),
    },
    fieldInvalid: {
      borderColor: _var(CSS_VARIABLES.mainError),
    },
    fieldEmpty: {},
    block: {
      margin: '0 0 25px',
    },
    row: {
      display: 'flex',
      width: '100%',
      margin: '0 0 25px',
    },
    col: {
      flex: 1,
      flexShrink: 0,
      flexGrow: 1,
      '&:first-child': {
        margin: '0 35px 0 0',
      },
      '&:last-child': {
        position: 'relative',
        '&:after': {
          content: "' '",
          position: 'absolute',
          top: '34px',
          right: '10px',
          width: '31px',
          height: '24px',
          background: `url(${cvcIcon}) no-repeat center`,
          backgroundSize: '31px auto',
        },
      },
    },
    label: {
      margin: '0 0 10px',
      color: _var(CSS_VARIABLES.mainColor),
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
    },
  });
};

export const StyledFormHelperText = appWithStyles(() => ({
  root: {
    fontSize: '9px',
    margin: '5px 0 0',
    color: _var(CSS_VARIABLES.errorColor),
  },
}))(FormHelperText);

export const StyledLabel = appWithStyles(() => ({
  root: {
    marginLeft: 0,
  },
  label: {
    marginLeft: '10px',
    color: _var(CSS_VARIABLES.mainColor),
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
  },
}))(FormControlLabel);
