import React, { useMemo } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { AddCardDialog } from '@shared/components/payments/stripe/add-card-stripe-component/add-card-dialog';
import { AddCardComponentViewModel } from '@shared/components/payments/stripe/add-card-stripe-component/add-card-stripe-component.vm';
import { Elements } from '@stripe/react-stripe-js';

import { styles } from './add-card-stripe-component.styles';

type AddCardComponentProps = AppWithStyles<typeof styles> & {
  isOpen: boolean;
  onCancel: () => void;
  loading: boolean;
  onSave: (data: any, save: boolean) => void;
  showSave: boolean;
  confirmButtonLabel?: string;
};

const AddCardStripeComponent: React.FC<AddCardComponentProps> = ({
  isOpen,
  onSave,
  onCancel,
  loading,
  showSave,
  confirmButtonLabel,
}) => {
  const $vm = useMemo(() => new AddCardComponentViewModel(), []);
  return (
    <Elements stripe={$vm.stripe}>
      <AddCardDialog
        showSave={showSave}
        isOpen={isOpen}
        loading={loading}
        onSave={onSave}
        onCancel={onCancel}
        confirmButtonLabel={confirmButtonLabel}
      />
    </Elements>
  );
};

export const AddCardStripe = appWithStyles(styles)(AddCardStripeComponent);
