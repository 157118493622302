import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { AppSwitch } from '@shared/components/new-design/switch';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { useMutation } from 'react-query';

import { AddCardComponentViewModel } from './add-card-worldpay-component.vm';

import { styles, StyledLabel } from './add-card-worldpay-component.styles';

export interface AddCardComponentProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onCancel: () => void;
  showSave: boolean;
  loading: boolean;
  onSave: (data: any, save: boolean) => void;
  confirmButtonLabel?: string;
  error?: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const AddCardComponent: FC<AddCardComponentProps> = ({
  classes,
  onCancel,
  onSave,
  isOpen,
  showSave,
  loading,
  confirmButtonLabel,
  error,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const [cardHolder, setCardHolder] = useState('');
  const [isActiveCardHolder, setIsActiveCardHolder] = useState(false);

  const [saveCard, setSaveCard] = useState<boolean>(showSave ? false : true);
  const [cardHolderError, setCardHolderError] = useState<string | null>(null);

  const $vm = useMemo(() => new AddCardComponentViewModel(), []);

  const initFormMutation = useMutation(['init-form'], () => $vm.intiForm());

  useEffect(() => {
    if (isOpen) {
      initFormMutation.mutate();
    } else {
      removeErrors();
      setCardHolder('');
      setSaveCard(false);
      $vm.removeCheckoutSDK();
    }
  }, [isOpen]);

  const removeErrors = () => {
    setCardHolderError(null);
  };

  const validateCardHolder = () => {
    if ($vm.isInvalidCardHolder(cardHolder)) {
      setCardHolderError(t`Wrong card details`);
    } else {
      setCardHolderError(null);
    }
  };

  const onChangeCardHolder = (name: string) => {
    validateCardHolder();
    setCardHolder(name);
  };

  const validateForm = () => {
    removeErrors();
    let isValid: boolean = true;

    if ($vm.isInvalidCardHolder(cardHolder)) {
      isValid = false;
      setCardHolderError(t`Wrong card details`);
    }

    validateCardHolder();
    if ($vm.isInvalidCardNumber()) {
      isValid = false;
    }

    if ($vm.isInvalidExpiration()) {
      isValid = false;
    }

    if ($vm.isInvalidCVV()) {
      isValid = false;
    }

    return isValid;
  };

  const handleSave = async () => {
    const isValidForm = validateForm();

    if (isValidForm) {
      try {
        const session = await $vm.createSession();

        onSave({ session, cardHolder }, saveCard);
      } catch (ex) {
        handleClose();
      }

      return;
    }
  };

  const handleChangeSaveCard = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setSaveCard(checked),
    [setSaveCard],
  );

  const changeIsActiveStatusCardHolder = (isActive: boolean) => {
    setIsActiveCardHolder(isActive);
  };

  const handleClose = useCallback(() => {
    removeErrors();
    setCardHolder('');
    setSaveCard(false);
    $vm.removeCheckoutSDK();
    onCancel();
  }, [onCancel]);

  let component = (
    <div className={classes.viewBlock}>
      <div className={classes.title}>{t`Add new card`}</div>
      <form className={classes.root} id="worldpay-form">
        <div className={classes.block}>
          <div className={classes.label}>{t`Card Number`}</div>
          <section
            id="card-pan"
            className={classNames(classes.field, { [classes.error as string]: error })}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <div className={classes.label}>{t`Expiration`}</div>
            <section id="card-expire" className={classes.field} />
          </div>
          <div className={classes.col}>
            <div className={classes.label}>{t`CVC`}</div>
            <section id="card-cvc" className={classes.field} />
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.label}>{t`Card Holder`}</div>
          <section
            id="card-holder-name"
            className={classNames(classes.field, {
              ['is-invalid']: Boolean(cardHolderError),
              ['is-onfocus']: isActiveCardHolder,
            })}
          >
            <input
              value={cardHolder}
              type="text"
              placeholder={t`Card Holder`}
              className={classNames(classes.cardHolderName, {
                ['is-invalid']: Boolean(cardHolderError),
              })}
              onChange={(e) => onChangeCardHolder(e.target.value)}
              onFocus={() => changeIsActiveStatusCardHolder(true)}
              onBlur={() => changeIsActiveStatusCardHolder(false)}
            />
          </section>
        </div>
        {showSave && (
          <div className={classes.block}>
            <StyledLabel
              control={<AppSwitch checked={saveCard} onChange={handleChangeSaveCard} />}
              label={t`Save the card`}
            />
          </div>
        )}
      </form>
      <div className={classes.submit}>
        <SubmitButton
          isLoading={loading}
          label={confirmButtonLabel || t`Continue`}
          onSubmit={handleSave}
        />
      </div>
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={handleClose}>
        <div className={classes.drawerContent}>{component}</div>
        <CloseDrawerButton onClick={handleClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{component}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(AddCardComponent);
