import { DiEntityIdentifier } from '@core/di/types';

function createTokens<Key extends string>(x: Record<Key, DiEntityIdentifier>) {
  return x;
}

export const DI_TOKENS = createTokens({
  configService: 'config-service',
  appHttpClientService: 'app-http-client-service',
  configHttpClientService: 'config-http-client-service',
  layoutVM: 'layout-vm',
  ratesVM: 'rates-vm',
  localesVM: 'locales-vm',
  baseService: 'base-service',
  authService: 'auth-service',
  storageService: 'storage-service',
  loggerService: 'logger-service',
  walletsService: 'wallets-service',
  ratesService: 'rates-service',
  ordersService: 'orders-service',
  usersService: 'users-service',
  cardService: 'card-service',
  KYCService: 'KYC-service',
  errorMessagesService: 'errorMessagesService',
  worldpayService: 'worldpayService',
  stripeService: 'stripeService',
  paymentService: 'paymenyService',
  worldpayStrategy: 'worldpayStrategy',
  stripeStrategy: 'stripeStritegy',
  productsService: 'productsService',
  productsVM: 'productsViewModel',
  ordersSmartCoinService: 'ordersSmartCoinService',
  smartCoinTrackingService: 'smartCoinTrackingService',
  smartCoinNotificaionService: 'smartCoinNotificaionService',
  smartCoinRepositoryService: 'smartCoinRepositoryService',
  notificationService: 'notificationService',
  smartCoinDetailsDialogViewModel: 'smartCoinDetailsDialogViewModel',
  transactionsNotificaionService: 'transactionsNotificaionService',
  secure3DRepositoryService: 'Secure3DRepositoryService',
});
