import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './no-data.styles';

export interface NoDataProps extends AppWithStyles<typeof styles> {
  height: string;
  text: string;
}

const NoDataComponent: React.FC<NoDataProps> = (props) => {
  const { classes, height, text } = props;
  const style = height ? { height: height } : {};
  return (
    <div className={classes.root} style={style}>
      <div className={classes.spacer}>
        <div className={classes.text}>{text}</div>
      </div>
    </div>
  );
};

export const NoData = appWithStyles(styles)(NoDataComponent);
